import { useEffect, useMemo, useState } from "react";
import moment from "moment";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import {
  Button,
  CircularProgress,
  Table as MuiTable,
  OutlinedInput,
  Stack,
  TableCell,
  Tooltip,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Select, MenuItem, FormControl } from "@mui/material";

//  Dashboard React components
import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";

//  Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import ServeSafe from "services/serveSafe.service";
import { useSelector } from "react-redux";
import VuiBadge from "components/VuiBadge";
import PopupData from "services/popup.service";

function Author({ image, name, email, phone }) {
  return (
    <VuiBox display="flex" alignItems="center">
      <VuiBox mr={0.1}>
        <VuiAvatar src={image} alt={"logo"} size="sm" variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {name}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {phone}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {email}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}
const Row = ({ row, index, currentPage }) => {
  const loading = useSelector((state) => state.loaders.isLoadingBannedPopupStatus[row?._id]);
  const [banned, setBanned] = useState(row?.banned || false);
  const id = row?._id;
  useEffect(() => {
    setBanned(row?.banned);
  }, [row?.banned]);

  const handleChange = async (event) => {
    setBanned(event.target.value);
    const [success] = await PopupData.updateBannedPopup({ banned: event.target.value, id });
    if (!success || !success?.data?.success) setBanned(banned);
  };

  let backgroundColor = !banned ? "#e31a1a !important" : "#01b574 !important";

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, padding: 0 }}>
      <TableCell size={"small"} component="td">
        {(currentPage - 1) * 50 + index + 1}
      </TableCell>
      <TableCell align="center">
        <VuiBadge
          variant="standard"
          color="success"
          size="lg"
          container
          sx={({ borders: { borderRadius } }) => ({
            background: row?.color,
            borderRadius: borderRadius.md,
          })}
        />
      </TableCell>
      <TableCell align="center">
        <VuiBox mr={0.1} align="center">
          <VuiAvatar src={row?.thumbnail} alt={"logo"} size="sm" variant="square" />
        </VuiBox>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          width="max-content"
        >
          {row?.name || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell padding="none" size={"small"} component="td">
        <Author
          name={row?.chefDetails?.firstName + " " + row?.chefDetails?.lastName}
          email={row?.chefDetails?.email}
          phone={row?.chefDetails?.phone}
          image={row?.chefDetails?.image}
        />
      </TableCell>
      <TableCell align="center">
        <Tooltip title={row?.description} placement="top">
          <VuiTypography
            variant="h6"
            textTransform="capitalize"
            align="left"
            color="white"
            fontWeight="medium"
            width="350px"
            noWrap
          >
            {row?.description || "--"}
          </VuiTypography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.phone || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell size={"small"} align="center">
        <Button variant="contained" color="primary">
          <VuiTypography variant="button" color="white" fontWeight="regular">
            View
          </VuiTypography>
        </Button>
      </TableCell>
      <TableCell align="center">
        <VuiTypography variant="h6" align="center" color="white" fontWeight="medium" width="350px">
          {row?.website || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        {row?.isDeleted ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {row?.isPublished ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {!loading ? (
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              ".MuiInputBase-formControl": {
                color: "white !important",
                backgroundColor,
              },
            }}
          >
            <Select value={banned} onChange={handleChange}>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="2vh">
            <CircularProgress />
          </VuiBox>
        )}
      </TableCell>
      <TableCell align="center">
        {row?.repeatPopupInterest ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.noOfSeats || "0"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.costPerSeat > 0 ? `$${row?.costPerSeat}` : "0"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "auto",
            minWidth: "200px",
            gap: "8px",
          }}
        >
          {row.seatingLocation?.length > 0
            ? row?.seatingLocation?.map((item) => (
                <VuiBadge
                  variant="standard"
                  badgeContent={item?.name}
                  color="success"
                  size="md"
                  container
                  sx={({
                    palette: { white, secondary },
                    borders: { borderRadius, borderWidth },
                  }) => ({
                    background: secondary.main,
                    border: `${borderWidth[1]} solid white`,
                    borderRadius: borderRadius.md,
                    color: white.main,
                  })}
                />
              ))
            : "--"}
        </div>
      </TableCell>
      <TableCell align="center">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "auto",
            minWidth: "200px",
            gap: "8px",
          }}
        >
          {row.seatingTypes?.length > 0
            ? row?.seatingTypes?.map((item) => (
                <VuiBadge
                  variant="standard"
                  badgeContent={item?.name}
                  color="success"
                  size="md"
                  container
                  sx={({
                    palette: { white, secondary },
                    borders: { borderRadius, borderWidth },
                  }) => ({
                    background: secondary.main,
                    border: `${borderWidth[1]} solid white`,
                    borderRadius: borderRadius.md,
                    color: white.main,
                  })}
                />
              ))
            : "--"}
        </div>
      </TableCell>
      <TableCell align="center">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "auto",
            minWidth: "200px",
            gap: "8px",
          }}
        >
          {row.diningExperience?.length > 0
            ? row?.diningExperience?.map((item) => (
                <VuiBadge
                  variant="standard"
                  badgeContent={item?.name}
                  color="success"
                  size="md"
                  container
                  sx={({
                    palette: { white, secondary },
                    borders: { borderRadius, borderWidth },
                  }) => ({
                    background: secondary.main,
                    border: `${borderWidth[1]} solid white`,
                    borderRadius: borderRadius.md,
                    color: white.main,
                  })}
                />
              ))
            : "--"}
        </div>
      </TableCell>

      <TableCell align="center">
        <div style={{ width: "300px", display: "flex", flexDirection: "column", gap: "10px" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "auto",
              gap: "8px",
            }}
          >
            <VuiTypography variant="h6" align="left" color="white" fontWeight="medium">
              Chef: &nbsp; &nbsp;&nbsp;
            </VuiTypography>
            {row?.popupImages?.[0]?.images
              ? row?.popupImages?.[0]?.images?.map((item) => (
                  <VuiAvatar src={item} alt={"logo"} size="sm" variant="square" />
                ))
              : "--"}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "auto",
              gap: "8px",
            }}
          >
            <VuiTypography variant="h6" align="left" color="white" fontWeight="medium">
              Food: &nbsp; &nbsp;
            </VuiTypography>
            {row?.popupImages?.[1]?.images
              ? row?.popupImages?.[1]?.images?.map((item) => (
                  <VuiAvatar src={item} alt={"logo"} size="sm" variant="square" />
                ))
              : "--"}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "auto",
              gap: "8px",
            }}
          >
            <VuiTypography variant="h6" align="left" color="white" fontWeight="medium">
              popup:
            </VuiTypography>
            {row?.popupImages?.[2]?.images
              ? row?.popupImages?.[2]?.images?.map((item) => (
                  <VuiAvatar src={item} alt={"logo"} size="sm" variant="square" />
                ))
              : "--"}
          </div>
        </div>
      </TableCell>
      <TableCell align="center">
        <VuiTypography variant="h6" align="center" color="white" fontWeight="medium" width="350px">
          {row?.facebookAccountLink || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography variant="h6" align="center" color="white" fontWeight="medium" width="350px">
          {row?.instagramAccountLink || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography variant="h6" align="center" color="white" fontWeight="medium" width="350px">
          {row?.tiktokAccountLink || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell size={"small"}>
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.createdAt ? moment(row?.createdAt).format("MM/DD/YYYY") : ""}
        </VuiTypography>
      </TableCell>
      <TableCell size={"small"}>
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.updatedAt ? moment(row?.updatedAt).format("MM/DD/YYYY") : ""}
        </VuiTypography>
      </TableCell>
    </TableRow>
  );
};
function Table({ columns, rows = [], currentPage }) {
  const { grey } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <VuiBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="text"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
      >
        {name.toUpperCase()}
      </VuiBox>
    );
  });
  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </VuiBox>
          <TableBody>
            {rows?.map((row, index) => (
              <Row key={row._id} row={row} index={index} currentPage={currentPage} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;

const getUniqueItemsById = (items) => items.map((item, index) => ({ ...item, id: index + 1 }));
