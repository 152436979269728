import { getDispatch } from "utils/dispatch.util";
import { loadersActions } from "store/slices/loader.slice";
import { serveSafeActions } from "store/slices/serveSafe.slice";
import http from "./http.service";

const ServeSafe = {
  async getServeSafeRecord({ currentPage, searchBy = "" }) {
    const dispatch = getDispatch();
    dispatch(loadersActions.setLoadingServeSafe(true));
    const [res, error] = await http.get(`/admin/serve-safe?search=${searchBy}&page=${currentPage}`);
    dispatch(loadersActions.setLoadingServeSafe(false));
    if (res?.data?.success === true) {
      dispatch(serveSafeActions.setServeSafeRecord(res?.data));
    }
    return [res, error];
  },

  async updateServeSafeRecord({ status, id }) {
    const dispatch = getDispatch();
    dispatch(loadersActions.setLoadingUpdateServeSafe({ id, loading: true }));
    const [res, error] = await http.patch(`/admin/serve-safe/${id}`, { status });
    dispatch(loadersActions.setLoadingUpdateServeSafe({ id, loading: false }));

    return [res, error];
  },
};

export default ServeSafe;
