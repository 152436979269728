import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingLogin: false,
  isLoadingServeSafe: false,
  isLoadingPopupData: false,
  isLoadingUpdateServeSafe: {},
  isLoadingBannedPopupStatus: {},
  isLoadingBannedChefStatus: {},
  isLoadingAllowPremium: {},
  isLoadingGuest: false,
  isLoadingReser: false,
  isLoadingChef: false,
};

const loadersSlice = createSlice({
  name: "loaders",
  initialState,
  reducers: {
    setLoadingLogin: (state, action) => {
      state.isLoadingLogin = action.payload;
    },

    // Guest slice
    setLoadingServeSafe: (state, action) => {
      state.isLoadingServeSafe = action.payload;
    },
    setLoadingUpdateServeSafe: (state, action) => {
      const { id, loading } = action.payload;
      if (loading) state.isLoadingUpdateServeSafe[id] = true;
      else delete state.isLoadingUpdateServeSafe[id];
    },

    // popup slice
    setLoadingPopupData: (state, action) => {
      state.isLoadingPopupData = action.payload;
    },
    setLoadingBannedPopupStatus: (state, action) => {
      const { id, loading } = action.payload;
      if (loading) state.isLoadingBannedPopupStatus[id] = true;
      else delete state.isLoadingBannedPopupStatus[id];
    },

    // guest slice
    setLoadingGuestData: (state, action) => {
      state.isLoadingGuest = action.payload;
    },

    // reservation slice
    setLoadingResData: (state, action) => {
      state.isLoadingReser = action.payload;
    },

    // chef slice
    setLoadingChefData: (state, action) => {
      state.isLoadingChef = action.payload;
    },
    setLoadingBannedChefStatus: (state, action) => {
      const { id, loading } = action.payload;
      if (loading) state.isLoadingBannedChefStatus[id] = true;
      else delete state.isLoadingBannedChefStatus[id];
    },
    setLoadingAllowPremium: (state, action) => {
      const { id, loading } = action.payload;
      if (loading) state.isLoadingAllowPremium[id] = true;
      else delete state.isLoadingAllowPremium[id];
    },
  },
});

export const loadersActions = loadersSlice.actions;
export default loadersSlice.reducer;
