import { useEffect, useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import {
  Button,
  CircularProgress,
  Table as MuiTable,
  OutlinedInput,
  TableCell,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Select, MenuItem, FormControl } from "@mui/material";

//  Dashboard React components
import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";

//  Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

import VuiBadge from "components/VuiBadge";
import moment from "moment";

function Author({ image, name, email }) {
  return (
    <VuiBox display="flex" alignItems="left">
      <VuiBox mr={0.1}>
        <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {name || "--"}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {email || "--"}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}
const Row = ({ row, index, currentPage }) => {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, padding: 0 }}>
      <TableCell size={"small"} component="td">
        {(currentPage - 1) * 50 + index + 1}
      </TableCell>
      <TableCell padding="none" size={"small"} component="td">
        <Author
          name={row?.guestDetails?.firstName + " " + row?.guestDetails?.lastName}
          email={row?.guestDetails?.email}
          image={row?.image}
        />
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.guestDetails?.phone || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.venueSlot || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.noOfGuests || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {/* {row?.amount || "--"} */}
          {row?.amount > 0 ? `$${row?.amount}` : "0"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        {row?.invitedGuestDetails?.map((item) => (
          <VuiTypography
            variant="h6"
            textTransform="capitalize"
            align="center"
            color="white"
            fontWeight="medium"
            noWrap
          >
            {item?.phone || "--"}
            {row?.invitedGuestDetails?.length > 1 ? "," : ""}
          </VuiTypography>
        ))}
      </TableCell>
      <TableCell align="center">
        {row?.status === "paid" ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Paid&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : row?.status === "held" ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Held&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, warning }, borders: { borderRadius, borderWidth } }) => ({
              background: warning.main,
              border: `${borderWidth[1]} solid ${warning.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent={row?.status}
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {row?.isDeleted ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell size={"small"}>
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.venueDate ? moment(row?.venueDate).format("MM/DD/YYYY") : ""}
        </VuiTypography>
      </TableCell>
      <TableCell size={"small"}>
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.createdAt ? moment(row?.createdAt).format("MM/DD/YYYY") : ""}
        </VuiTypography>
      </TableCell>
      <TableCell size={"small"}>
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.updatedAt ? moment(row?.updatedAt).format("MM/DD/YYYY") : ""}
        </VuiTypography>
      </TableCell>
    </TableRow>
  );
};
function Table({ columns, rows = [], currentPage }) {
  const { grey } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <VuiBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="text"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
      >
        {name.toUpperCase()}
      </VuiBox>
    );
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </VuiBox>
          <TableBody>
            {rows?.map((row, index) => (
              <Row key={row._id} row={row} index={index} currentPage={currentPage} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
