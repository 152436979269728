import http from "./http.service";
import { getDispatch } from "utils/dispatch.util";
import { loadersActions } from "store/slices/loader.slice";
import { chefDataActions } from "store/slices/chefData.slice";

const ChefData = {
  async getChefData({ currentPage, searchBy = "" }) {
    const dispatch = getDispatch();
    dispatch(loadersActions.setLoadingChefData(true));
    const [res, error] = await http.get(`admin/chef?search=${searchBy}&page=${currentPage}`);
    dispatch(loadersActions.setLoadingChefData(false));
    if (res?.data?.success === true) {
      dispatch(chefDataActions.setChefData(res?.data));
    }
    return [res, error];
  },

  async updateBannedChef({ banned, id }) {
    const dispatch = getDispatch();
    dispatch(loadersActions.setLoadingBannedChefStatus({ id, loading: true }));
    const [res, error] = await http.patch(`/admin/chef/${id}`, { banned });
    dispatch(loadersActions.setLoadingBannedChefStatus({ id, loading: false }));
    return [res, error];
  },

  async allowChefPremium({ allowPremium, id }) {
    const dispatch = getDispatch();
    dispatch(loadersActions.setLoadingAllowPremium({ id, loading: true }));
    const [res, error] = await http.patch(`/admin/chef/${id}/premium`, { allowPremium });
    dispatch(loadersActions.setLoadingAllowPremium({ id, loading: false }));
    return [res, error];
  },

  async setCashoutWithoutHold({ cashoutWithoutHold, cashoutWithoutHoldTime, id }) {
    const [res, error] = await http.patch(`/admin/chef/${id}/cashout`, {
      cashoutWithoutHold,
      cashoutWithoutHoldTime,
    });
    return [res, error];
  },
};

export default ChefData;
