export default {
  columns: [
    { id: 1, name: "#", align: "left" },
    { id: 2, name: "chef Details", align: "left" },
    { id: 3, name: "phone", align: "center" },
    { id: 4, name: "Allow Premium", align: "center" },
    { id: 5, name: "Cashout Without Hold", align: "center" },
    { id: 6, name: "description", align: "left" },
    { id: 7, name: "about Chef", align: "left" },
    { id: 8, name: "current Popups Per Month", align: "center" },
    { id: 9, name: "popup Goals Per Month", align: "center" },
    { id: 10, name: "last On boarding Stage", align: "center" },
    { id: 11, name: "Hosting Date And Time", align: "center" },
    { id: 12, name: "Email Verified", align: "center" },
    { id: 13, name: "Phone Verified", align: "center" },
    { id: 14, name: "payment Verified", align: "center" },
    { id: 15, name: "Premium", align: "center" },
    { id: 16, name: "Deleted", align: "center" },
    { id: 17, name: "Banned", align: "center" },
    { id: 18, name: "facebook Account Link", align: "center" },
    { id: 19, name: "instagram Account Link", align: "center" },
    { id: 20, name: "tiktok Account Link", align: "center" },
    { id: 21, name: "birthday", align: "center" },
    { id: 22, name: "pronouns", align: "center" },
    { id: 23, name: "created At", align: "center" },
    { id: 24, name: "updatedAt", align: "center" },
  ],
};
