import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Table as MuiTable,
  Select,
  TableCell,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

//  Dashboard React components
import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";

//  Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

import VuiBadge from "components/VuiBadge";
import ChefData from "services/chef.service";

function Author({ image, name, email }) {
  return (
    <VuiBox display="flex" alignItems="left">
      <VuiBox mr={1}>
        <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {name}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {email}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}
const cashoutWithoutHoldOptions = [
  { label: "Default", value: undefined },
  { label: "Immediately", value: 0 },
  { label: "24h", value: 24 },
  { label: "48h", value: 48 },
];
const Row = ({ row, index, currentPage }) => {
  const id = row?._id;
  const [chefBanned, setChefBanned] = useState(row?.banned || false);
  const [allowPremium, setAllowPremium] = useState(row?.allowPremium || false);
  const [cashoutWithoutHoldLoader, setCashoutWithoutHoldLoader] = useState(false);
  const [cashoutWithoutHold, setCashoutWithoutHold] = useState(
    cashoutWithoutHoldOptions.find(
      ({ value }) =>
        value ===
        (row?.cashoutWithoutHold && row?.cashoutWithoutHoldTime >= 0
          ? row?.cashoutWithoutHoldTime
          : undefined)
    ) || cashoutWithoutHoldOptions[0]
  );

  const loading = useSelector((state) => state.loaders.isLoadingBannedChefStatus[row?._id]);
  const isLoadingAllowPremium = useSelector(
    (state) => state.loaders.isLoadingAllowPremium?.[row?._id]
  );

  useEffect(() => {
    setChefBanned(row?.banned);
  }, [row?.banned]);

  useEffect(() => {
    setAllowPremium(row?.allowPremium || false);
  }, [row?.allowPremium]);

  useEffect(() => {
    setCashoutWithoutHold(
      cashoutWithoutHoldOptions.find(
        ({ value }) =>
          value ===
          (row?.cashoutWithoutHold && row?.cashoutWithoutHoldTime >= 0
            ? row?.cashoutWithoutHoldTime
            : undefined)
      ) || cashoutWithoutHoldOptions[0]
    );
  }, [row?.cashoutWithoutHold, row?.cashoutWithoutHoldTime]);

  const handleChangeAlow = async (event) => {
    setAllowPremium(event.target.value);
    const [success] = await ChefData.allowChefPremium({ id, allowPremium: event.target.value });
    if (!success || !success?.data?.success) setAllowPremium(allowPremium);
  };

  const handleChangeCashoutWithoutHold = async (event) => {
    const { value } = event.target;
    setCashoutWithoutHoldLoader(true);
    setCashoutWithoutHold(value);
    const [success] = await ChefData.setCashoutWithoutHold({
      id,
      cashoutWithoutHold: value.value !== undefined,
      cashoutWithoutHoldTime: value.value === undefined ? undefined : value.value,
    });
    setCashoutWithoutHoldLoader(false);

    if (!success || !success?.data?.success) setCashoutWithoutHold(cashoutWithoutHold);
  };

  const handleChange = async (event) => {
    setChefBanned(event.target.value);
    const [success] = await ChefData.updateBannedChef({ banned: event.target.value, id });
    if (!success || !success?.data?.success) setChefBanned(chefBanned);
  };

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, padding: 0 }}>
      <TableCell size={"small"} component="td">
        {(currentPage - 1) * 50 + index + 1}
      </TableCell>
      <TableCell padding="none" size={"small"} component="td">
        <Author name={row?.firstName + " " + row?.lastName} email={row?.email} image={row?.image} />
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.phone || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        {!isLoadingAllowPremium ? (
          <FormControl
            size="small"
            fullWidth={true}
            variant="outlined"
            sx={{
              ".MuiInputBase-formControl": {
                color: "white !important",
                border: !["Allow", "Disallow"].includes(allowPremium)
                  ? "1px solid white !important"
                  : "transparent !important",
                background: !allowPremium ? "#e31a1a !important" : "#01b574 !important",
              },
            }}
          >
            <Select
              value={allowPremium}
              onChange={handleChangeAlow}
              renderValue={(selected) => (selected ? "Allowed" : "Not allowed")}
            >
              <MenuItem value={true}>Allow</MenuItem>
              <MenuItem value={false}>Not Allow</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="1vh">
            <CircularProgress />
          </VuiBox>
        )}
      </TableCell>
      <TableCell align="center">
        {!cashoutWithoutHoldLoader ? (
          <FormControl
            size="small"
            fullWidth={true}
            variant="outlined"
            sx={{
              ".MuiInputBase-formControl": {
                color: "white !important",
                backgroundColor: "transparent !important",
              },
            }}
          >
            <Select
              value={cashoutWithoutHold}
              onChange={handleChangeCashoutWithoutHold}
              renderValue={(selected) => selected?.label}
            >
              {cashoutWithoutHoldOptions.map((option) => (
                <MenuItem key={option.value} value={option}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="1vh">
            <CircularProgress />
          </VuiBox>
        )}
      </TableCell>
      <TableCell align="center">
        <Tooltip title={row?.description} placement="top">
          <VuiTypography
            variant="h6"
            textTransform="capitalize"
            align="left"
            color="white"
            fontWeight="medium"
            width="350px"
            noWrap
          >
            {row?.description || "--"}
          </VuiTypography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={row?.aboutChef} placement="top">
          <VuiTypography
            variant="h6"
            textTransform="capitalize"
            align="left"
            color="white"
            fontWeight="medium"
            width="350px"
            noWrap
          >
            {row?.aboutChef || "--"}
          </VuiTypography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.currentPopupsPerMonth >= 0 ? row?.currentPopupsPerMonth : "0"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.popupGoalsPerMonth >= 0 ? row?.popupGoalsPerMonth : "0"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.lastOnboardingStage || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={row?.typicalHostingDateAndTime} placement="top">
          <VuiTypography
            variant="h6"
            textTransform="capitalize"
            align="center"
            color="white"
            fontWeight="medium"
            width="350px"
            noWrap
          >
            {row?.typicalHostingDateAndTime || "--"}
          </VuiTypography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        {row?.isEmailVerified ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {row?.isPhoneVerified ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {row?.paymentVerified ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {row?.isPremium ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {row?.isDeleted ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {!loading ? (
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              ".MuiInputBase-formControl": {
                color: "white !important",
                backgroundColor: !chefBanned ? "#e31a1a !important" : "#01b574 !important",
              },
            }}
          >
            <Select value={chefBanned || false} onChange={handleChange}>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="2vh">
            <CircularProgress />
          </VuiBox>
        )}
      </TableCell>
      <TableCell align="center">
        <VuiTypography variant="h6" align="center" color="white" fontWeight="medium" noWrap>
          {row?.facebookAccountLink || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography variant="h6" align="center" color="white" fontWeight="medium" noWrap>
          {row?.instagramAccountLink || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography variant="h6" align="center" color="white" fontWeight="medium" noWrap>
          {row?.tiktokAccountLink || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.birthday ? moment(row?.birthday).format("MM/DD/YYYY") : "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.pronouns || "--"}
        </VuiTypography>
      </TableCell>

      <TableCell size={"small"}>
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.createdAt ? moment(row?.createdAt).format("MM/DD/YYYY") : ""}
        </VuiTypography>
      </TableCell>
      <TableCell size={"small"}>
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.updatedAt ? moment(row?.updatedAt).format("MM/DD/YYYY") : ""}
        </VuiTypography>
      </TableCell>
    </TableRow>
  );
};
function Table({ columns, rows = [], currentPage }) {
  const { grey } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <VuiBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="text"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
      >
        {name.toUpperCase()}
      </VuiBox>
    );
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </VuiBox>
          <TableBody>
            {rows?.map((row, index) => (
              <Row key={row?._id} row={row} index={index} currentPage={currentPage} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
